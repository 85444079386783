import { VerticalTabsModal } from "@ninjaone/components"
import { saveCustomFieldValues } from "js/includes/common/client/customFields"
import { createUnmanagedDevice } from "js/includes/common/client/unmanagedDevices"
import { filterEditableFieldValues } from "js/includes/components/CustomFields/common"
import { useCustomFieldsValidator } from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/hooks/useCustomFieldsValidator"
import { mapObjIndexed, pluck } from "ramda"
import { useCallback, useRef } from "react"
import AddUnmanagedDeviceCoreFields from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/AddUnmanagedDeviceCoreFields"
import AddUnmanagedDeviceCustomFields from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/AddUnmanagedDeviceCustomFields"
import AddUnmanagedDeviceRoles from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/AddUnmanagedDeviceRoles"
import { useMountedState } from "@ninjaone/utils"
import {
  arrayToMapWithKey,
  isNotNilOrEmpty,
  localizationKey,
  reportErrorAndShowMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"

export const stepName = Object.freeze({
  ROLES: "roles",
  CORE_FIELDS: "coreFields",
  CUSTOM_FIELDS: "customFields",
})

export default function AddUnmanagedDeviceTabModal({
  fields,
  nodeRoleId,
  loadingFields,
  loadingRoles,
  onChange,
  unmanagedDeviceRoles,
  unmount,
  validation,
  validateForm,
  validateFormFields,
  values,
}) {
  const [saving, setSaving] = useMountedState(false)
  const [validationError, setValidationError] = useMountedState(false)

  const customFieldValuesRef = useRef({})

  const resetCustomFieldValues = useCallback(() => {
    customFieldValuesRef.current = {}
  }, [])

  const { validate: validateCustomFields } = useCustomFieldsValidator({
    customFieldValuesRef,
    fields,
  })

  const handleSaveCustomFieldValues = useCallback(
    async ({ values, nodeId }) => {
      const parsedValues = mapObjIndexed(
        (value, key) => ({
          attributeId: parseInt(key, 10),
          value,
        }),
        values,
      )

      const editableValues = filterEditableFieldValues(fields, parsedValues)

      if (editableValues.length) {
        const response = await saveCustomFieldValues({
          entity: "node",
          values: editableValues,
          nodeId,
        })
        const updatedValues = arrayToMapWithKey("attributeId", response)

        if (updatedValues) {
          return updatedValues
        }
      }
    },
    [fields],
  )

  const handleCreateUnmanagedDevice = useCallback(async () => {
    const invalidCoreFields = !validateForm()
    const fieldIds = pluck("id", fields)
    const validationErrors = validateCustomFields(fieldIds, customFieldValuesRef.current)
    const invalidCustomFields = isNotNilOrEmpty(validationErrors)

    setValidationError(invalidCustomFields)

    if (saving || invalidCoreFields || invalidCustomFields) {
      return
    }

    setSaving(true)

    try {
      const createdUnmanagedDevice = await createUnmanagedDevice({
        name: values.name,
        orgId: values.organization.id,
        locationId: values.location.id,
        assetTypeId: nodeRoleId,
        serialNumber: values.serialNumber,
        assignedUserUUID: values.assignedUser?.uid,
        warrantyStartDate: values.warrantyStartDate,
        warrantyEndDate: values.warrantyEndDate,
      })

      await handleSaveCustomFieldValues({
        values: customFieldValuesRef.current,
        nodeId: createdUnmanagedDevice.nodeId,
      })

      unmount()

      showSuccessMessage(localized("Unmanaged device created succesfully"))

      window.location.href = `#/unmanagedDeviceDashboard/${createdUnmanagedDevice.nodeId}/overview`
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error creating unmanaged device"))
    } finally {
      setSaving(false)
    }
  }, [
    fields,
    handleSaveCustomFieldValues,
    nodeRoleId,
    saving,
    setSaving,
    setValidationError,
    unmount,
    validateCustomFields,
    validateForm,
    values.assignedUser?.uid,
    values.location?.id,
    values.name,
    values.organization?.id,
    values.serialNumber,
    values.warrantyEndDate,
    values.warrantyStartDate,
  ])

  const tabs = [
    {
      label: localized("Select role"),
      required: true,
      hasError: !nodeRoleId,
      renderer: () => (
        <AddUnmanagedDeviceRoles
          {...{
            loadingRoles,
            nodeRoleId,
            onChange,
            resetCustomFieldValues,
            setValidationError,
            unmanagedDeviceRoles,
          }}
        />
      ),
    },
    {
      label: localized("Device information"),
      required: true,
      hasError:
        validation.success.name === false ||
        validation.success.nodeRole === false ||
        validation.success.location === false ||
        validation.success.organization === false,
      renderer: () => (
        <AddUnmanagedDeviceCoreFields
          {...{
            onChange,
            saving,
            validation,
            validateFormFields,
            values,
          }}
        />
      ),
    },
    {
      label: localized("Custom fields"),
      required: fields.some(field => field.required),
      hasError: isNotNilOrEmpty(fields) && validationError,
      renderer: () => (
        <AddUnmanagedDeviceCustomFields
          {...{
            customFieldValuesRef,
            setValidationError,
            validate: validateCustomFields,

            fields,
            loadingFields,
          }}
        />
      ),
    },
  ]

  return (
    <VerticalTabsModal
      cancelable
      tabsAriaLabel={localized("Add an unmanaged device")}
      unmount={unmount}
      tabs={tabs}
      titleGroup={{
        titleText: localized("Add an unmanaged device"),
        textWrap: false,
      }}
      onPrimaryButtonClick={handleCreateUnmanagedDevice}
    />
  )
}
