import { useInitialValues } from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/hooks/useInitialValues"
import { useCallback, useMemo } from "react"
import { buildFormValidate } from "js/includes/components/CustomFields/fieldTypes"
import { isNilOrEmpty } from "js/includes/common/utils"

export const useCustomFieldsValidator = ({ customFieldValuesRef = { current: {} }, fields = [] }) => {
  const { defaultValues } = useInitialValues({
    customFieldValuesRef,
    fields,
  })

  const validators = useMemo(() => buildFormValidate(fields), [fields])

  const validate = useCallback(
    (fieldIds, fieldValues, initialValues = defaultValues) => {
      const errors = {}

      if (isNilOrEmpty(fieldIds)) {
        return errors
      }

      const values = isNilOrEmpty(fieldValues) ? initialValues : fieldValues

      fieldIds.forEach(fieldId => {
        const validator = validators[fieldId]

        if (validator) {
          const { type = "validate", success, message } = validator(values[fieldId])

          if (!success) {
            errors[fieldId] = {
              type,
              message,
            }
          }
        }
      })

      return errors
    },
    [defaultValues, validators],
  )

  return {
    validate,
  }
}
