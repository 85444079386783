import { isNotNilOrEmpty } from "js/includes/common/utils"
import { fieldTypes, parseCustomFieldValue } from "js/includes/components/CustomFields/fieldTypes"
import { reduce } from "ramda"
import { useMemo } from "react"

const mapCustomFieldToValue = (fields, values = {}) =>
  fields.reduce((acc, field) => {
    if (field.id) {
      acc[field.id] = parseCustomFieldValue(field, values[field.id])
    }

    return acc
  }, {})

const getEditorInitialValues = (fields, values) => {
  return reduce(
    (acc, field) => {
      if (values[field.id]) {
        acc[field.id] = fieldTypes[field.type]?.getInitialValue(field, values[field.id])
      }
      return acc
    },
    {},
    fields,
  )
}

export const useInitialValues = ({ customFieldValuesRef, fields }) => {
  const initialValues = useMemo(
    () =>
      isNotNilOrEmpty(customFieldValuesRef.current)
        ? customFieldValuesRef.current
        : getEditorInitialValues(fields, customFieldValuesRef.current),
    [customFieldValuesRef, fields],
  )

  const defaultValues = useMemo(() => mapCustomFieldToValue(fields, initialValues), [fields, initialValues])

  return {
    initialValues,
    defaultValues,
  }
}
