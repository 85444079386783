import { keys } from "ramda"
import { useCallback } from "react"
import { useForm } from "react-hook-form"
import { isNotNilOrEmpty } from "js/includes/common/utils"
import { useInitialValues } from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/hooks/useInitialValues"
import { useSetContentRefs } from "js/includes/components/CustomFields/WYSIWYGEditor/plugins/InlineImagePlugin/hooks/useSetContentRefs"

export const useRoleCustomFieldsEditor = ({ customFieldValuesRef, fields, validate, setValidationError }) => {
  const { defaultValues, initialValues } = useInitialValues({
    customFieldValuesRef,
    fields,
  })

  const methods = useForm({
    mode: "all",
    shouldFocusError: false,
    defaultValues,
    resolver: (values, context, options) => {
      const errors = validate(keys(values), values, defaultValues)
      setValidationError(isNotNilOrEmpty(errors))
      customFieldValuesRef.current = values
      return { values, errors }
    },
  })

  const [attachmentRefs, setRefs] = useSetContentRefs()

  const handleCacheTempImages = useCallback(
    ({ uploadsByContentIdRef, tempUriByContentIdRef }) => setRefs({ uploadsByContentIdRef, tempUriByContentIdRef }),
    [setRefs],
  )

  return {
    attachmentRefs,
    defaultValues,
    handleCacheTempImages,
    initialValues,
    methods,
  }
}
