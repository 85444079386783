import { getReadableNodeRole } from "js/includes/common/_enums"
import { getCustomFields } from "js/includes/common/client/customFields"
import { formatCustomItem } from "js/includes/components/CustomFields/fieldTypes"
import AddUnmanagedDeviceTabModal from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/AddUnmanagedDeviceTabModal"
import { reduce } from "ramda"
import { useEffect } from "react"
import { reportErrorAndShowMessage, validations } from "js/includes/common/utils"
import { useForm, useMountedState } from "js/includes/common/hooks"
import { getNodeRoles } from "js/includes/common/client"

const addUnmanagedDeviceInitialState = {
  name: "",
  organization: null,
  location: null,
  nodeRole: null,
  serialNumber: "",
  assignedUser: null,
  warrantyStartDate: null,
  warrantyEndDate: null,
}

export default function AddUnmanagedDeviceModal({ unmount }) {
  const [loadingRoles, setLoadingRoles] = useMountedState(true)
  const [loadingFields, setLoadingFields] = useMountedState(false)
  const [unmanagedDeviceRoles, setUnmanagedDeviceRoles] = useMountedState([])
  const [fields, setFields] = useMountedState([])

  const { values, onChange, validateForm, validateFormFields, validation } = useForm({
    fields: addUnmanagedDeviceInitialState,
    validate: {
      name: validations.required,
      nodeRole: validations.required,
      location: validations.required,
      organization: validations.required,
    },
  })

  useEffect(() => {
    ;(async function getUnmanagedDeviceRoles() {
      try {
        const response = await getNodeRoles()
        const unmanagedDeviceRoles = response.filter(
          ({ nodeClass, custom }) => nodeClass === "UNMANAGED_DEVICE" && custom,
        )
        setUnmanagedDeviceRoles(unmanagedDeviceRoles)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoadingRoles(false)
      }
    })()
  }, [setLoadingRoles, setUnmanagedDeviceRoles])

  const nodeClass = values?.nodeRole?.nodeClass
  const nodeRoleId = values?.nodeRole?.id
  const nodeRoleName = values?.nodeRole ? getReadableNodeRole(values?.nodeRole?.name) : ""

  useEffect(() => {
    ;(async function getRoleCustomFields() {
      if (!nodeRoleId) return

      try {
        setLoadingFields(true)
        const nodeRoleFieldsResponse = await getCustomFields({
          entity: "node",
          attributeScope: "role",
          entityId: nodeRoleId,
          definitionScope: "node",
        })
        const nodeClassFieldsResponse = nodeClass
          ? await getCustomFields({
              entity: "node",
              attributeScope: "class",
              entityId: nodeClass,
              definitionScope: "node",
            })
          : []
        const fields = reduce(
          (acc, item) => {
            const id = item.id || item.uiElementUid

            if (acc.added.has(id)) {
              return acc
            }

            acc.added.add(id)

            acc.items.push(formatCustomItem(item))

            return acc
          },
          {
            added: new Set(),
            items: [],
          },
          [...nodeClassFieldsResponse, ...nodeRoleFieldsResponse],
        ).items

        setFields(fields)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoadingFields(false)
      }
    })()
  }, [nodeRoleId, nodeClass, setFields, setLoadingFields])

  return (
    <AddUnmanagedDeviceTabModal
      {...{
        fields,
        nodeRoleId,
        nodeRoleName,
        loadingFields,
        loadingRoles,
        onChange,
        unmount,
        unmanagedDeviceRoles,
        validation,
        validateForm,
        validateFormFields,
        values,
      }}
    />
  )
}
