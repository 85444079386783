import moment from "moment/moment"
import { DatePicker, Input, Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { localizationKey, localized } from "js/includes/common/utils"
import { SECTION_MAX_HEIGHT, SECTION_WIDTH } from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/constants"
import LocationPicker from "js/includes/components/OrganizationLocationPicker/LocationPicker"
import OrganizationPicker from "js/includes/components/OrganizationLocationPicker/OrganizationPicker"
import { Box } from "js/includes/components/Styled"
import { AppUsersAndContactsDropdown } from "js/includes/ticketing/shared/components"

export default function AddUnmanagedDeviceCoreFields({
  onChange,
  saving,
  validation,
  validateFormFields,
  values: { name, organization, location, serialNumber, assignedUser, warrantyStartDate, warrantyEndDate },
}) {
  return (
    <Box paddingLeft="2px" paddingRight="2px" height={SECTION_MAX_HEIGHT} width={SECTION_WIDTH} overflowY="auto">
      <Box>
        <Input
          required
          name="name"
          labelText={localized("Name")}
          value={name}
          onChange={onChange}
          onBlur={() => validateFormFields("name")}
          maxLength={200}
          minWidth="100%"
          errorMessage={validation.message.name}
          disabled={saving}
        />
      </Box>

      <OrganizationPicker
        required
        labelAbove
        openOnFocus
        disabled={saving}
        searchPlaceholderToken={localizationKey("Selected organization")}
        changeOrganization={organization => {
          onChange("organization", organization)
        }}
        validationState={validation?.success?.organization === false ? "error" : null}
        {...{ organization, validateForm: validateFormFields }}
      />

      <LocationPicker
        required
        labelAbove
        changeLocation={location => {
          onChange("location", {
            id: location.value,
            name: location.label,
          })
        }}
        disabled={saving || !organization?.id}
        validationState={validation?.success?.location === false ? "error" : null}
        {...{ organization, location }}
      />

      <Box marginTop={sizer(4)}>
        <Text type="body" token={localizationKey("Assigned user")} color="colorTextStrong" />

        <AppUsersAndContactsDropdown
          disabled={saving || !organization}
          clientId={organization?.id}
          onSelect={user => onChange("assignedUser", user)}
          value={assignedUser}
          enableClear={false}
          ariaAttributes={{
            "aria-labelledby": "assigned-user-label",
          }}
        />
      </Box>

      <Box marginTop={sizer(4)}>
        <Text type="body" token={localized("Warranty start date")} color="colorTextStrong" />

        <DatePicker
          fullWidth
          useDropdownNavigation
          ariaLabel={localized("warranty start date picker")}
          disabled={saving}
          selectedDays={warrantyStartDate ? new Date(warrantyStartDate) : null}
          onDayChange={({ date }) => {
            const dateValue = date ? moment(date).valueOf() : null

            onChange("warrantyStartDate", dateValue)

            if (warrantyEndDate && dateValue > warrantyEndDate) {
              onChange("warrantyEndDate", null)
            }
          }}
        />
      </Box>

      <Box marginTop={sizer(4)}>
        <Text type="body" token={localized("Warranty end date")} color="colorTextStrong" />

        <DatePicker
          fullWidth
          useDropdownNavigation
          ariaLabel={localized("warranty end date picker")}
          disabled={saving}
          disabledDays={[
            {
              before: warrantyStartDate ? new Date(warrantyStartDate) : null,
            },
          ]}
          selectedDays={warrantyEndDate ? new Date(warrantyEndDate) : null}
          onDayChange={({ date }) => {
            onChange("warrantyEndDate", date ? moment(date).valueOf() : null)
          }}
        />
      </Box>

      <Box marginTop={sizer(4)}>
        <Input
          name="serialNumber"
          labelText={localized("Serial number")}
          value={serialNumber}
          onChange={onChange}
          onBlur={() => validateFormFields("serialNumber")}
          maxLength={200}
          minWidth="100%"
          errorMessage={validation.message.serialNumber}
          disabled={saving}
        />
      </Box>
    </Box>
  )
}
