import { ControlLabel } from "react-bootstrap"
import tokens from "@ninjaone/tokens"
import { AngleDownIcon } from "@ninjaone/icons"
import { Button } from "@ninjaone/components"
import { StyledCol } from "js/includes/components/Styled/Form"
import { localized } from "js/includes/common/utils"
import { Label } from "@ninjaone/components/src/Form/Label"

export default function FormGroupLabel({
  label,
  required,
  tooltipText,
  labelProps,
  expanded,
  setExpanded,
  type,
  technicianPermission,
}) {
  const isWYSIWYGField = type === "WYSIWYG"

  return (
    <StyledCol xs={4} componentClass={ControlLabel} {...labelProps}>
     {label && <Label {...{ forInputElement: false, labelText: label, required, tooltipText }} />}

      {technicianPermission === "EDITABLE" && isWYSIWYGField && !expanded && (
        <Button
          offset={{
            left: tokens.spacing[1],
          }}
          Icon={AngleDownIcon}
          iconPlacement="right"
          variant="tertiary"
          onClick={() => setExpanded(true)}
          labelText={localized("Edit content")}
        />
      )}
    </StyledCol>
  )
}
