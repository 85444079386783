import { useRoleCustomFieldsEditor } from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/hooks/useRoleCustomFieldsEditor"
import { useEffect } from "react"
import { FormProvider } from "react-hook-form"
import { localizationKey } from "js/includes/common/utils"
import CustomFieldsForm from "js/includes/components/CustomFields/CustomFieldsForm"
import { SECTION_MAX_HEIGHT, SECTION_WIDTH } from "js/includes/components/DeviceModal/AddUnmanagedDeviceModal/constants"
import Loading from "js/includes/components/Loading"
import NoDataFound from "js/includes/components/NoDataFound"
import { Box, Flex } from "js/includes/components/Styled"

export default function AddUnmanagedDeviceCustomFields({
  customFieldValuesRef,
  setValidationError,
  validate,

  fields,
  loadingFields,
}) {
  const { attachmentRefs, handleCacheTempImages, initialValues, methods } = useRoleCustomFieldsEditor({
    customFieldValuesRef,
    fields,
    validate,
    setValidationError,
  })

  useEffect(() => {
    methods.trigger()
  }, [methods])

  return loadingFields ? (
    <Flex height={SECTION_MAX_HEIGHT} width={SECTION_WIDTH} justifyContent="center" alignItems="center">
      <Loading withExtraSpace />
    </Flex>
  ) : fields.length ? (
    <Box paddingLeft="2px" paddingRight="2px" height={SECTION_MAX_HEIGHT} width={SECTION_WIDTH} overflowY="auto">
      <FormProvider {...methods}>
        <form id="custom-fields-form">
          <CustomFieldsForm
            {...{
              attachmentRefs,
              fields,
              initialValues,
              handleCacheTempImages,
              shouldOverflow: false,
            }}
          />
        </form>
      </FormProvider>
    </Box>
  ) : (
    <NoDataFound labelToken={localizationKey("No fields found")} />
  )
}
